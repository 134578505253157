// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-analytics-js": () => import("./../../../src/pages/Analytics.js" /* webpackChunkName: "component---src-pages-analytics-js" */),
  "component---src-pages-assets-js": () => import("./../../../src/pages/Assets.js" /* webpackChunkName: "component---src-pages-assets-js" */),
  "component---src-pages-billing-js": () => import("./../../../src/pages/Billing.js" /* webpackChunkName: "component---src-pages-billing-js" */),
  "component---src-pages-budget-js": () => import("./../../../src/pages/Budget.js" /* webpackChunkName: "component---src-pages-budget-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-engage-js": () => import("./../../../src/pages/Engage.js" /* webpackChunkName: "component---src-pages-engage-js" */),
  "component---src-pages-enrol-js": () => import("./../../../src/pages/enrol.js" /* webpackChunkName: "component---src-pages-enrol-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrate-js": () => import("./../../../src/pages/Integrate.js" /* webpackChunkName: "component---src-pages-integrate-js" */),
  "component---src-pages-maintain-js": () => import("./../../../src/pages/Maintain.js" /* webpackChunkName: "component---src-pages-maintain-js" */),
  "component---src-pages-online-js": () => import("./../../../src/pages/Online.js" /* webpackChunkName: "component---src-pages-online-js" */),
  "component---src-pages-oracle-js": () => import("./../../../src/pages/oracle.js" /* webpackChunkName: "component---src-pages-oracle-js" */),
  "component---src-pages-plan-js": () => import("./../../../src/pages/Plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/Process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-procure-js": () => import("./../../../src/pages/Procure.js" /* webpackChunkName: "component---src-pages-procure-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-purchase-js": () => import("./../../../src/pages/Purchase.js" /* webpackChunkName: "component---src-pages-purchase-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */)
}

